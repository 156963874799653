import React from "react"
import { Layout } from "../components/layout"
import styled from "styled-components"
import PageHeading from "../components/PageHeading/PageHeading"
import { graphql } from "gatsby"
import { LazyBlockFactory } from "../util/LazyBlockFactory"
import { Container } from "@material-ui/core"

const breadcrumbData = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Terms and Conditions",
    url: "/terms-and-conditions",
  },
]

const TermsAndConditions: React.FC = ({ data }) => {
  const blocks = LazyBlockFactory(data.wpPage.lazy_data, data.wpPage.title)
  return (
    <Layout title={"Graduate Fashion Week - Terms and Conditions"}>
      <FullWidth>
        <PageHeading breadcrumbData={breadcrumbData} pageTitle="Terms and Conditions" />
      </FullWidth>
      <Container maxWidth={"lg"}>{blocks.map(v => v)}</Container>
    </Layout>
  )
}

export default TermsAndConditions

export const pageQuery = graphql`
  query terms {
    wpPage(title: { eq: "Terms and Conditions" }) {
      id
      lazy_data
      title
    }
  }
`

const FullWidth = styled.div`
  width: 100vw;
  /* max-width: 1200px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
`

const PartnershipContainer = styled.div`
  width: 100vw;
  max-width: 1200px;
  justify-content: center;
  align-items: center;
  z-index: 10;
  margin-bottom: 80px;
`
